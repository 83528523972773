export const getToken = (value) => {
    if (value === 'proton') {
        return "XPR";
    } else if (value === 'wax') {
        return "WAXP";
    } else if (value === 'telos') {
        return "TLOS";
    }
}

export const MainNet = {
    PROTON: {
        baseUrl: "proton-api.eosiomadrid.io",
        homePageUrl: "https://proton.chainscout.io/dashboard_monitor",
        chain: "384da888112027f0321850a169f737c33e53b388aad48b5adace4bab97f437e0"
    },
    WAX: {
        //baseUrl: "https://wax-api.eosiomadrid.io",
        baseUrl: "https://waxapi.ledgerwise.io",
        homePageUrl: "https://wax.chainscout.io/dashboard_monitor",
        chain: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4"
    },
    TLOS: {
        baseUrl: "hyperion.telosmadrid.io",
        homePageUrl: "https://telos.chainscout.io/dashboard_monitor",
        chain: "4667b205c6838ef70ff7988f6e8257e8be0e1284a2f59699054a018f743b1d11"
    }
}

export const TestNet = {
    PROTON: {
        baseUrl: "proton-api-testnet.eosiomadrid.io",
        homePageUrl: "https://proton-testnet.chainscout.io/dashboard_monitor",
        chain: "71ee83bcf52142d61019d95f9cc5427ba6a0d7ff8accd9e2088ae2abeaf3d3dd"
    },
    WAX: {
        baseUrl: "wax-testnet-hyperion.eosiomadrid.io",
        homePageUrl: "https://wax-testnet.chainscout.io/dashboard_monitor",
        chain: "f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12"
    },
    TLOS: {
        baseUrl: "hyperion-testnet.telosmadrid.io",
        homePageUrl: "https://telos-testnet.chainscout.io/dashboard_monitor",
        chain: "1eaa0824707c8c16bd25145493bf062aecddfeb56c736f6ba6397f3195f33c9f"
    }
}

export const getHostNetwork = () => {
    const hostName = window.location.host;
    if (hostName.includes('wax-testnet')) {
        return { value: "wax", type: 'test', token: getToken("wax") };
    } else if (hostName.includes('wax')) {
        return { value: "wax", type: 'main', token: getToken("wax") };
    } else if (hostName.includes('proton-testnet')) {
        return { value: "proton", type: 'test', token: getToken("proton") };
    } else if (hostName.includes('proton')) {
        return { value: "proton", type: 'main', token: getToken("proton") };
    } else if (hostName.includes('telos-testnet')) {
        return { value: "telos", type: 'test', token: getToken("telos") };
    } else if (hostName.includes('telos')) {
        return { value: "telos", type: 'main', token: getToken("telos") };
    } else {
        //return { value: "proton", type: "main", token: getToken("proton") };
        //return { value: "telos", type: 'main', token: getToken("telos") };
        return { value: "wax", type: 'main', token: getToken("wax") };
    }

}