import { getToken } from "../../../utility/common";
import {
  GET_ACCOUNT_INFO_ERROR,
  GET_ACCOUNT_INFO_REQUEST,
  GET_ACCOUNT_INFO_SUCCESS,
  GET_ACTION_HISTORY_ERROR,
  GET_ACTION_HISTORY_REQUEST,
  GET_ACTION_HISTORY_SUCCESS,
  GET_BLOCK_DATA_ERROR,
  GET_BLOCK_DATA_REQUEST,
  GET_BLOCK_DATA_SUCCESS,
  GET_CHART_DATA_ERROR,
  GET_CHART_DATA_REQUEST,
  GET_CHART_DATA_SUCCESS,
  GET_MONITOR_BLOCK_ERROR,
  GET_MONITOR_BLOCK_REQUEST,
  GET_MONITOR_BLOCK_SUCCESS,
  GET_TOKEN_DATA_ERROR,
  GET_TOKEN_DATA_REQUEST,
  GET_TOKEN_DATA_SUCCESS,
  GET_TRANSACTION_HISTORY_ERROR,
  GET_TRANSACTION_HISTORY_REQUEST,
  GET_TRANSACTION_HISTORY_SUCCESS,
  REFRESH_ACCOUNT_PAGE,
  SET_ACCOUNT_LISTING,
  SET_NETWORK_BLOCKCHAIN,
} from "../../actions/blockchain/types";

import { getHostNetwork } from "../../../utility/common";

export const setLocalStorageNetworkData = ({ type, value }) => {
  localStorage.setItem("network-type", type);
  localStorage.setItem("network-value", value);
};
const createSlug = (network) => {
  switch (network.type) {
    case "main":
      if (network.value === "proton") return "PROTON_MAINNET";
      if (network.value === "wax") return "WAX_MAINNET";
      if (network.value === "telos") return "TELOS_MAINNET";
    case "test":
      if (network.value === "proton") return "PROTON_TESTNET";
      if (network.value === "wax") return "WAX_TESTNET";
      if (network.value === "telos") return "TELOS_TESTNET";
  }
};

const initialState = {
  network: {
    value: getHostNetwork().value || localStorage.getItem("network-value") || "telos",
    type: getHostNetwork().type || localStorage.getItem("network-type") || "main",
    token: getHostNetwork().token || getToken(localStorage.getItem("network-value") || "telos"),
    slug: createSlug({
      value: getHostNetwork().value || localStorage.getItem("network-value") || "telos",
      type: getHostNetwork().type || localStorage.getItem("network-type") || "main",
    }),
  },
  monitorBlockData: null,
  loading: false,
  chartData: [],
  accountInfo: null,
  actionHistory: null,
  tokenData: null,
  blockData: null,
  trxData: null,
  accountListing: [],
  refreshAccountPage: false
};

const blockchainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NETWORK_BLOCKCHAIN:
      return {
        ...state,
        network: {
          value: action.payload.value,
          type: action.payload.type,
          token: getToken(action.payload.value),
          slug: createSlug({
            value: action.payload.value,
            type: action.payload.type,
          }),
        },
      };
    case GET_MONITOR_BLOCK_REQUEST:
      return { ...state, loading: false };
    case GET_MONITOR_BLOCK_SUCCESS:
      return { ...state, loading: false, monitorBlockData: action.payload };
    case GET_MONITOR_BLOCK_ERROR:
      return { ...state, loading: false, monitorBlockData: null };
    case GET_TOKEN_DATA_REQUEST:
      return { ...state, loading: false };
    case GET_TOKEN_DATA_SUCCESS:
      return { ...state, loading: false, tokenData: action.payload };
    case GET_TOKEN_DATA_ERROR:
      return { ...state, loading: false, tokenData: null };
    case GET_CHART_DATA_REQUEST:
      return { ...state, loading: true };
    case GET_CHART_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        chartData: action.payload.data,
        heading: action.payload.token,
      };
    case GET_CHART_DATA_ERROR:
      return { ...state, loading: false, chartData: null, heading: "" };
    case GET_ACCOUNT_INFO_REQUEST:
      return { ...state, loading: true };
    case GET_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        accountInfo: action.payload.data,
      };
    case GET_ACCOUNT_INFO_ERROR:
      return { ...state, loading: false, accountInfo: null };
    case GET_ACCOUNT_INFO_REQUEST:
      return { ...state, loading: true };
    case GET_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        accountInfo: action.payload.data,
      };
    case GET_ACCOUNT_INFO_ERROR:
      return { ...state, loading: false, accountInfo: null };
    case GET_ACTION_HISTORY_REQUEST:
      return { ...state, loading: true };
    case GET_ACTION_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        actionHistory: action.payload.data,
      };
    case GET_ACTION_HISTORY_ERROR:
      return { ...state, loading: false, accountInfo: null };
    case GET_BLOCK_DATA_REQUEST:
      return { ...state, loading: true };
    case GET_BLOCK_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        blockData: action.payload.data,
      };
    case GET_BLOCK_DATA_ERROR:
      return { ...state, loading: false, accountInfo: null };
    case GET_TRANSACTION_HISTORY_REQUEST:
      return { ...state, loading: true };
    case GET_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        trxData: action.payload.data,
      };
    case GET_TRANSACTION_HISTORY_ERROR:
      return { ...state, loading: false, trxData: null };
    case SET_ACCOUNT_LISTING:
      return { ...state, accountListing: action.payload };
    case REFRESH_ACCOUNT_PAGE:
      return { ...state, refreshAccountPage: !state.refreshAccountPage };
    default:
      return { ...state };
  }
};
export default blockchainReducer;
