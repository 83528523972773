import { UALJs } from 'ual-plainjs-renderer';
import { Wax } from '@eosdacio/ual-wax';
import { isEmpty } from 'lodash';
import { Anchor } from 'ual-anchor';

import { storeAppDispatch } from '../../../../redux/storeConfig/store';
import { setPlayerBalance, setPlayerData, setPlayerLogout } from './GlobalState/UserReducer';
import { MainNet, TestNet, getHostNetwork } from '../../../../utility/common';

/**
 * Class to manage user data; it will be saved on Login and deleted on Logout
 */
export class User {

    appName = "test app"
    baseUrl = "";
    chain = "";
    myChain = {}
    constructor() {
        this.network = getHostNetwork().value//localStorage.getItem("network-value");
        this.typeNet = getHostNetwork().type === "main" ? MainNet : TestNet; //localStorage.getItem("network-type")

        if (this.network === 'proton') {
            this.baseUrl = this.typeNet.PROTON.baseUrl
            this.chain = this.typeNet.PROTON.chain
        } else if (this.network === 'wax') {
            this.baseUrl = this.typeNet.WAX.baseUrl
            this.chain = this.typeNet.WAX.chain
        } else if (this.network === 'telos') {
            this.baseUrl = this.typeNet.TLOS.baseUrl
            this.chain = this.typeNet.TLOS.chain
        }

        // if (window.location.origin === "https://proton.chainscout.io") {
        //     this.baseUrl = MainNet.PROTON.baseUrl
        //     this.chain = MainNet.PROTON.chain
        // } else if (window.location.origin === 'https://proton-testnet.chainscout.io') {
        //     this.baseUrl = TestNet.PROTON.baseUrl
        //     this.chain = TestNet.PROTON.chain
        // } else if (window.location.origin === 'https://wax.chainscout.io') {
        //     this.baseUrl = MainNet.WAX.baseUrl
        //     this.chain = MainNet.WAX.chain
        // } else if (window.location.origin === 'https://wax-testnet.chainscout.io') {
        //     this.baseUrl = TestNet.WAX.baseUrl
        //     this.chain = TestNet.WAX.chain
        // } else if (window.location.origin === 'https://telos.chainscout.io') {
        //     this.baseUrl = MainNet.TLOS.baseUrl
        //     this.chain = MainNet.TLOS.chain
        // } else if (window.location.origin === 'https://telos-testnet.chainscout.io') {
        //     this.baseUrl = TestNet.TLOS.baseUrl
        //     this.chain = TestNet.TLOS.chain
        // }
        this.myChain = {

            chainId: this.chain,
            rpcEndpoints: [{
                protocol: 'https',
                host: this.baseUrl,
                port: ''
            }]
        };
    }


    ual;

    // User session data
    authName = undefined;
    serviceLoginName = undefined;
    // Shows petition signing and current balance obtaining methods
    session = undefined;

    // Current balance
    userBalance = 0;

    // Callback to refer to successful login
    callbackServerUserData = undefined;

    getName() {
        return this.authName;
    }

    login(callback) {
        const ualButton = document.querySelector(".ual-button-gen");
        ualButton.click();

        this.callbackServerUserData = callback;
    }

    isLogged() {
        const auth = !isEmpty(this.authName) && !isEmpty(this.session);
        return auth;
    }

    logout() {
        this.authName = undefined;
        this.session = undefined;

        this.ual.logoutUser();

        storeAppDispatch(setPlayerLogout());

        if (this.callbackServerUserData !== undefined) {
            this.callbackServerUserData();
        }
    }

    // UAL API call response
    async ualCallback(userObject) {

        this.session = userObject[0];
        this.serviceLoginName = this.session.constructor.name;
        this.authName = this.session.accountName;

        storeAppDispatch(setPlayerData({
            name: this.authName,
            isLogged: this.isLogged(),
            balance: (this.balance !== undefined) ? this.balance : 0
        }));

        this.getBalance();

        if (this.callbackServerUserData !== undefined) {
            this.callbackServerUserData();
        }
    }

    addTransferButtonEventListener = async (demoTransaction, cb) => {
        // Update our demo transaction to use the logged in user
        try {
            const userAccountName = await this.session.getAccountName()
            demoTransaction.actions[0].authorization[0].actor = userAccountName
            demoTransaction.actions[0].data.from = userAccountName

            const response = await this.session.signTransaction(
                demoTransaction,
                { broadcast: true }
            )
            if (cb) {
                cb("", response);
            }
        } catch (err) {
            if (cb) {
                cb(err.message);
            }
        }
    }

    addStack_unstake_EventListener = async (demoTransaction, cb) => {
        try {
            const response = await this.session.signTransaction(
                demoTransaction,
                { broadcast: true }
            )
            if (cb) {
                cb("", response);
            }
        } catch (err) {
            if (cb) {
                cb(err.message);
            }
        }
    }

    buy_sell_ramEventListener = async (demoTransaction, cb) => {
        // Update our demo transaction to use the logged in user
        try {
            const response = await this.session.signTransaction(
                demoTransaction,
                { broadcast: true }
            )
            if (cb) {
                cb("", response);
            }
        } catch (err) {
            if (cb) {
                cb(err.message);
            }
        }
    }

    vote_EventListener = async (demoTransaction, cb) => {
        // Update our demo transaction to use the logged in user
        try {
            const userAccountName = await this.session.getAccountName()
            demoTransaction.actions[0].authorization[0].actor = userAccountName
            demoTransaction.actions[0].data.voter = userAccountName

            const response = await this.session.signTransaction(
                demoTransaction,
                { broadcast: true },
            )
            if (cb) {
                cb("", response);
            }
        } catch (err) {
            if (cb) {
                cb(err.message);
            }
        }
    }
    getBalance() {
        const balance = this.session.rpc.get_account(this.authName);
        balance.then((balance) => {
            this.balance = balance.core_liquid_balance;
            storeAppDispatch(setPlayerBalance((this.balance !== undefined) ? this.balance : 0));
        });
        return balance;
    }

    // UserService init called to prepare UAL Login.
    init() {
        // Binding:
        this.ualCallback = this.ualCallback.bind(this);
        
        const wax = new Wax([this.myChain], { appName: this.appName });

        const anchor = new Anchor([this.myChain], { appName: this.appName });

        const divUal = document.createElement('div')
        divUal.setAttribute('id', 'ual-login');
        document.body.appendChild(divUal);

        const divLoginRoot = document.getElementById('ual-login');


        // Check if this.myChain is equal to wax
        if (this.myChain.chainId === '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4') {
            // Code to be executed if this.myChain is equal to wax
            console.log('this.myChain.chainId is equal to wax MAINNET');
            this.ual = new UALJs(this.ualCallback, [this.myChain], this.appName, [anchor,wax], { containerElement: divLoginRoot });
        } else {
            // Code to be executed if this.myChain is not equal to wax
            console.log('this.myChain is not equal to wax');
            this.ual = new UALJs(this.ualCallback, [this.myChain], this.appName, [anchor], { containerElement: divLoginRoot });
        }

        this.ual.init()

    }

    static new() {
        if (!User.instance) {
            User.instance = new User();
        }

        return User.instance;
    }
}

export const UserService = User.new();
