export const GET_ACCOUNT_STATS_REQUEST = "GET_ACCOUNT_STATS_REQUEST"
export const GET_ACCOUNT_STATS_SUCCESS = "GET_ACCOUNT_STATS_SUCCESS"
export const GET_ACCOUNT_STATS_ERROR = "GET_ACCOUNT_STATS_ERROR"

export const GET_ACTION_BY_CONTRACT_REQUEST = "GET_ACTION_BY_CONTRACT_REQUEST"
export const GET_ACTION_BY_CONTRACT_SUCCESS = "GET_ACTION_BY_CONTRACT_SUCCESS"
export const GET_ACTION_BY_CONTRACT_ERROR = "GET_ACTION_BY_CONTRACT_ERROR"

export const GET_USER_BY_CONTRACT_REQUEST = "GET_USER_BY_CONTRACT_REQUEST"
export const GET_USER_BY_CONTRACT_SUCCESS = "GET_USER_BY_CONTRACT_SUCCESS"
export const GET_USER_BY_CONTRACT_ERROR = "GET_USER_BY_CONTRACT_ERROR"

export const GET_CONTRACT_STATS_REQUEST = "GET_CONTRACT_STATS_REQUEST"
export const GET_CONTRACT_STATS_SUCCESS = "GET_CONTRACT_STATS_SUCCESS"
export const GET_CONTRACT_STATS_ERROR = "GET_CONTRACT_STATS_ERROR"

export const GET_BP_STATUS_REQUEST = "GET_BP_STATUS_REQUEST";
export const GET_BP_STATUS_SUCCESS = "GET_BP_STATUS_SUCCESS";
export const GET_BP_STATUS_ERROR = "GET_BP_STATUS_ERROR";

export const GET_SEED_ENDPOINT_REQUEST = "GET_SEED_ENDPOINT_REQUEST";
export const GET_SEED_ENDPOINT_SUCCESS = "GET_SEED_ENDPOINT_SUCCESS";
export const GET_SEED_ENDPOINT_ERROR = "GET_SEED_ENDPOINT_ERROR";

export const GET_API_ENDPOINT_REQUEST = "GET_API_ENDPOINT_REQUEST";
export const GET_API_ENDPOINT_SUCCESS = "GET_API_ENDPOINT_SUCCESS";
export const GET_API_ENDPOINT_ERROR = "GET_API_ENDPOINT_ERROR";

export const GET_V1_ENDPOINT_REQUEST = "GET_V1_ENDPOINT_REQUEST";
export const GET_V1_ENDPOINT_SUCCESS = "GET_V1_ENDPOINT_SUCCESS";
export const GET_V1_ENDPOINT_ERROR = "GET_V1_ENDPOINT_ERROR";

export const GET_V2_ENDPOINT_REQUEST = "GET_V2_ENDPOINT_REQUEST";
export const GET_V2_ENDPOINT_SUCCESS = "GET_V2_ENDPOINT_SUCCESS";
export const GET_V2_ENDPOINT_ERROR = "GET_V2_ENDPOINT_ERROR";

export const GET_ATOMIC_ENDPOINT_REQUEST = "GET_ATOMIC_ENDPOINT_REQUEST";
export const GET_ATOMIC_ENDPOINT_SUCCESS = "GET_ATOMIC_ENDPOINT_SUCCESS";
export const GET_ATOMIC_ENDPOINT_ERROR = "GET_ATOMIC_ENDPOINT_ERROR";

export const GET_PRODUCER_ENDPOINT_REQUEST = "GET_PRODUCER_ENDPOINT_REQUEST";
export const GET_PRODUCER_ENDPOINT_SUCCESS = "GET_PRODUCER_ENDPOINT_SUCCESS";
export const GET_PRODUCER_ENDPOINT_ERROR = "GET_PRODUCER_ENDPOINT_ERROR";

export const GET_PRODUCER_FOR_ENDPOINT_REQUEST = "GET_PRODUCER_FOR_ENDPOINT_REQUEST";
export const GET_PRODUCER_FOR_ENDPOINT_SUCCESS = "GET_PRODUCER_FOR_ENDPOINT_SUCCESS";
export const GET_PRODUCER_FOR_ENDPOINT_ERROR = "GET_PRODUCER_FOR_ENDPOINT_ERROR";

export const GET_TOP_HOLDERS_REQUEST = "GET_TOP_HOLDERS_REQUEST";
export const GET_TOP_HOLDERS_SUCCESS = "GET_TOP_HOLDERS_SUCCESS";
export const GET_TOP_HOLDERS_ERROR = "GET_TOP_HOLDERS_ERROR";

export const GET_RAM_HOLDERS_REQUEST = "GET_RAM_HOLDERS_REQUEST";
export const GET_RAM_HOLDERS_SUCCESS = "GET_RAM_HOLDERS_SUCCESS";
export const GET_RAM_HOLDERS_ERROR = "GET_RAM_HOLDERS_ERROR";

export const GET_REX_HOLDERS_REQUEST = "GET_REX_HOLDERS_REQUEST";
export const GET_REX_HOLDERS_SUCCESS = "GET_REX_HOLDERS_SUCCESS";
export const GET_REX_HOLDERS_ERROR = "GET_REX_HOLDERS_ERROR";

export const GET_AGGREGATED_DATA_REQUEST = "GET_AGGREGATED_DATA_REQUEST";
export const GET_AGGREGATED_DATA_SUCCESS = "GET_AGGREGATED_DATA_SUCCESS";
export const GET_AGGREGATED_DATA_ERROR = "GET_AGGREGATED_DATA_ERROR";

export const GET_ENDPOINTS_MESSAGE_REQUEST = "GET_ENDPOINTS_MESSAGE_REQUEST";
export const GET_ENDPOINTS_MESSAGE_SUCCESS = "GET_ENDPOINTS_MESSAGE_SUCCESS";
export const GET_ENDPOINTS_MESSAGE_ERROR = "GET_ENDPOINTS_MESSAGE_ERROR";

export const GET_DISSAGREGATED_PRO_ENDPOINTS_REQUEST = "GET_DISSAGREGATED_PRO_ENDPOINTS_REQUEST";
export const GET_DISSAGREGATED_PRO_ENDPOINTS_SUCCESS = "GET_DISSAGREGATED_PRO_ENDPOINTS_SUCCESS";
export const GET_DISSAGREGATED_PRO_ENDPOINTS_ERROR = "GET_DISSAGREGATED_PRO_ENDPOINTS_ERROR";

export const GET_DISSAGRED_GIVEN_DATE_REQUEST = "GET_DISSAGRED_GIVEN_DATE_REQUEST";
export const GET_DISSAGRED_GIVEN_DATE_SUCCESS = "GET_DISSAGRED_GIVEN_DATE_SUCCESS";
export const GET_DISSAGRED_GIVEN_DATE_ERROR = "GET_DISSAGRED_GIVEN_DATE_ERROR";