import { GET_ACCOUNT_STATS_ERROR, GET_ACCOUNT_STATS_REQUEST, GET_ACCOUNT_STATS_SUCCESS, GET_ACTION_BY_CONTRACT_ERROR, GET_ACTION_BY_CONTRACT_REQUEST, GET_ACTION_BY_CONTRACT_SUCCESS, GET_AGGREGATED_DATA_ERROR, GET_AGGREGATED_DATA_REQUEST, GET_AGGREGATED_DATA_SUCCESS, GET_API_ENDPOINT_ERROR, GET_API_ENDPOINT_REQUEST, GET_API_ENDPOINT_SUCCESS, GET_ATOMIC_ENDPOINT_ERROR, GET_ATOMIC_ENDPOINT_REQUEST, GET_ATOMIC_ENDPOINT_SUCCESS, GET_BP_STATUS_ERROR, GET_BP_STATUS_REQUEST, GET_BP_STATUS_SUCCESS, GET_CONTRACT_STATS_ERROR, GET_CONTRACT_STATS_REQUEST, GET_CONTRACT_STATS_SUCCESS, GET_DISSAGREGATED_PRO_ENDPOINTS_ERROR, GET_DISSAGREGATED_PRO_ENDPOINTS_REQUEST, GET_DISSAGREGATED_PRO_ENDPOINTS_SUCCESS, GET_ENDPOINTS_MESSAGE_ERROR, GET_ENDPOINTS_MESSAGE_REQUEST, GET_ENDPOINTS_MESSAGE_SUCCESS, GET_PRODUCER_ENDPOINT_ERROR, GET_PRODUCER_ENDPOINT_REQUEST, GET_PRODUCER_ENDPOINT_SUCCESS, GET_PRODUCER_FOR_ENDPOINT_ERROR, GET_PRODUCER_FOR_ENDPOINT_REQUEST, GET_PRODUCER_FOR_ENDPOINT_SUCCESS, GET_RAM_HOLDERS_ERROR, GET_RAM_HOLDERS_REQUEST, GET_RAM_HOLDERS_SUCCESS, GET_REX_HOLDERS_ERROR, GET_REX_HOLDERS_REQUEST, GET_REX_HOLDERS_SUCCESS, GET_SEED_ENDPOINT_ERROR, GET_SEED_ENDPOINT_REQUEST, GET_SEED_ENDPOINT_SUCCESS, GET_TOP_HOLDERS_ERROR, GET_TOP_HOLDERS_REQUEST, GET_TOP_HOLDERS_SUCCESS, GET_USER_BY_CONTRACT_ERROR, GET_USER_BY_CONTRACT_REQUEST, GET_USER_BY_CONTRACT_SUCCESS, GET_V1_ENDPOINT_ERROR, GET_V1_ENDPOINT_REQUEST, GET_V1_ENDPOINT_SUCCESS, GET_V2_ENDPOINT_ERROR, GET_V2_ENDPOINT_REQUEST, GET_V2_ENDPOINT_SUCCESS } from "../../actions/getAccount/getAccountsType"

const initialState = {
    loading: false,
    accountStats: null,
    token_contract: null,
    unique_Contract: null,
    action_contract: null,
    bpStatus: null,
    seedEndpoint: null,
    apiEndpoint: null,
    v1Endpoint: null,
    v2Endpoint: null,
    atomicEndpoint: null,
    producerEndpoint: null,
    producerEndpointFor: null,
    aggregatedData: null,
    topHolders: null,
    ramHolders: null,
    rexHolders: null,
    endpointsMessage: null,
    disAggregatedData: null
}

const accountHolderReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ACCOUNT_STATS_REQUEST:
            return { ...state, loading: true }
        case GET_ACCOUNT_STATS_SUCCESS:
            return { ...state, loading: false, accountStats: action?.payload[0] }
        case GET_ACCOUNT_STATS_ERROR:
            return { ...state, loading: false }

        case GET_USER_BY_CONTRACT_REQUEST:
            return { ...state, loading: true }
        case GET_USER_BY_CONTRACT_SUCCESS:

            return { ...state, loading: false, unique_Contract: action.payload }
        case GET_USER_BY_CONTRACT_ERROR:
            return { ...state, loading: false }

        case GET_CONTRACT_STATS_REQUEST:
            return { ...state, loading: true }
        case GET_CONTRACT_STATS_SUCCESS:
            return { ...state, loading: false, token_contract: action.payload }
        case GET_CONTRACT_STATS_ERROR:
            return { ...state, loading: false }
        case GET_ACTION_BY_CONTRACT_REQUEST:
            return { ...state, loading: true }
        case GET_ACTION_BY_CONTRACT_SUCCESS:
            return { ...state, action_contract: action.payload, loading: false }
        case GET_ACTION_BY_CONTRACT_ERROR:
            return { ...state, loading: false }
        case GET_BP_STATUS_REQUEST:
            return { ...state, loading: true };
        case GET_BP_STATUS_SUCCESS:
            return { ...state, loading: false, bpStatus: action.payload };
        case GET_BP_STATUS_ERROR:
            return { ...state, loading: false, bpStatus: null };
        case GET_SEED_ENDPOINT_REQUEST:
            return { ...state, loading: true };
        case GET_SEED_ENDPOINT_SUCCESS:
            return { ...state, loading: false, seedEndpoint: action.payload };
        case GET_SEED_ENDPOINT_ERROR:
            return { ...state, loading: false, seedEndpoint: null };
        case GET_API_ENDPOINT_REQUEST:
            return { ...state, loading: true };
        case GET_API_ENDPOINT_SUCCESS:
            return { ...state, loading: false, apiEndpoint: action.payload };
        case GET_API_ENDPOINT_ERROR:
            return { ...state, loading: false, apiEndpoint: null };
        case GET_V1_ENDPOINT_REQUEST:
            return { ...state, loading: true };
        case GET_V1_ENDPOINT_SUCCESS:
            return { ...state, loading: false, v1Endpoint: action.payload };
        case GET_V1_ENDPOINT_ERROR:
            return { ...state, loading: false, v1Endpoint: null };
        case GET_V2_ENDPOINT_REQUEST:
            return { ...state, loading: true };
        case GET_V2_ENDPOINT_SUCCESS:
            return { ...state, loading: false, v2Endpoint: action.payload };
        case GET_V2_ENDPOINT_ERROR:
            return { ...state, loading: false, v2Endpoint: null };
        case GET_ATOMIC_ENDPOINT_REQUEST:
            return { ...state, loading: true };
        case GET_ATOMIC_ENDPOINT_SUCCESS:
            return { ...state, loading: false, atomicEndpoint: action.payload };
        case GET_ATOMIC_ENDPOINT_ERROR:
            return { ...state, loading: false, atomicEndpoint: null };
        case GET_TOP_HOLDERS_REQUEST:
            return { ...state, loading: true };
        case GET_TOP_HOLDERS_SUCCESS:
            return { ...state, loading: false, topHolders: action.payload };
        case GET_TOP_HOLDERS_ERROR:
            return { ...state, loading: false, topHolders: null };
        case GET_RAM_HOLDERS_REQUEST:
            return { ...state, loading: true };
        case GET_RAM_HOLDERS_SUCCESS:
            return { ...state, loading: false, ramHolders: action.payload };
        case GET_RAM_HOLDERS_ERROR:
            return { ...state, loading: false, ramHolders: null };
        case GET_REX_HOLDERS_REQUEST:
            return { ...state, loading: true };
        case GET_REX_HOLDERS_SUCCESS:
            return { ...state, loading: false, rexHolders: action.payload };
        case GET_REX_HOLDERS_ERROR:
            return { ...state, loading: false, rexHolders: null };    
        case GET_PRODUCER_ENDPOINT_REQUEST:
            return { ...state, loading: true };
        case GET_PRODUCER_ENDPOINT_SUCCESS:
            return { ...state, loading: false, producerEndpoint: action.payload };
        case GET_PRODUCER_ENDPOINT_ERROR:
            return { ...state, loading: false, producerEndpoint: null };
        case GET_PRODUCER_FOR_ENDPOINT_REQUEST:
            return { ...state, loading: true };
        case GET_PRODUCER_FOR_ENDPOINT_SUCCESS:
            return { ...state, loading: false, producerEndpointFor: action.payload };
        case GET_PRODUCER_FOR_ENDPOINT_ERROR:
            return { ...state, loading: false, producerEndpointFor: null };
        case GET_AGGREGATED_DATA_REQUEST:
            return { ...state, loading: true };
        case GET_AGGREGATED_DATA_SUCCESS:
            return { ...state, loading: false, aggregatedData: action.payload };
        case GET_AGGREGATED_DATA_ERROR:
            return { ...state, loading: false, aggregatedData: null };
        case GET_ENDPOINTS_MESSAGE_REQUEST:
            return { ...state, loading: true };
        case GET_ENDPOINTS_MESSAGE_SUCCESS:
            return { ...state, loading: false, endpointsMessage: action.payload };
        case GET_ENDPOINTS_MESSAGE_ERROR:
            return { ...state, loading: false, endpointsMessage: null };
        case GET_DISSAGREGATED_PRO_ENDPOINTS_REQUEST:
            return { ...state, loading: true };
        case GET_DISSAGREGATED_PRO_ENDPOINTS_SUCCESS:
            return { ...state, loading: false, disAggregatedData: action.payload };
        case GET_DISSAGREGATED_PRO_ENDPOINTS_ERROR:
            return { ...state, loading: false, disAggregatedData: null };
        default:
            return { ...state }
    }

}
export default accountHolderReducer;