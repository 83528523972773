export const SET_NETWORK_BLOCKCHAIN = "SET_NETWORK_BLOCKCHAIN";
export const GET_MONITOR_BLOCK_REQUEST = "GET_MONITOR_BLOCK_REQUEST";
export const GET_MONITOR_BLOCK_SUCCESS = "GET_MONITOR_BLOCK_SUCCESS";
export const GET_MONITOR_BLOCK_ERROR = "GET_MONITOR_BLOCK_ERROR";

export const GET_TOKEN_DATA_REQUEST = "GET_TOKEN_DATA_REQUEST";
export const GET_TOKEN_DATA_SUCCESS = "GET_TOKEN_DATA_SUCCESS";
export const GET_TOKEN_DATA_ERROR = "GET_TOKEN_DATA_ERROR";

export const GET_CHART_DATA_REQUEST = "GET_CHART_DATA_REQUEST"
export const GET_CHART_DATA_SUCCESS = "GET_CHART_DATA_SUCCESS"
export const GET_CHART_DATA_ERROR = "GET_CHART_DATA_ERROR"

export const GET_ACCOUNT_INFO_REQUEST = "GET_ACCOUNT_INFO_REQUEST"
export const GET_ACCOUNT_INFO_SUCCESS = "GET_ACCOUNT_INFO_SUCCESS"
export const GET_ACCOUNT_INFO_ERROR = "GET_ACCOUNT_INFO_ERROR"

export const GET_ACTION_HISTORY_REQUEST = "GET_ACTION_HISTORY_REQUEST"
export const GET_ACTION_HISTORY_SUCCESS = "GET_ACTION_HISTORY_SUCCESS"
export const GET_ACTION_HISTORY_ERROR = "GET_ACTION_HISTORY_ERROR"

export const GET_BLOCK_DATA_REQUEST = "GET_BLOCK_DATA_REQUEST"
export const GET_BLOCK_DATA_SUCCESS = "GET_BLOCK_DATA_SUCCESS"
export const GET_BLOCK_DATA_ERROR = "GET_BLOCK_DATA_ERROR"

export const GET_TRANSACTION_HISTORY_REQUEST = "GET_TRANSACTION_HISTORY_REQUEST"
export const GET_TRANSACTION_HISTORY_SUCCESS = "GET_TRANSACTION_HISTORY_SUCCESS"
export const GET_TRANSACTION_HISTORY_ERROR = "GET_TRANSACTION_HISTORY_ERROR"

export const SET_ACCOUNT_LISTING = "SET_ACCOUNT_LISTING";
export const REFRESH_ACCOUNT_PAGE = "REFRESH_ACCOUNT_PAGE";